/* eslint-disable import/no-cycle */
import { Box, Button, Stack, Typography } from '@mui/material';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { saveAs } from 'file-saver';

import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import LivingWillDoc from './LivingWillDoc';
import useLocales from '../../../../../../lib/hooks/useLocales';
import { ILivingWillForm } from '#/types/livingWill';
import { useGetRepresentative } from '#/api/representativeQueries';
import useAuth from '#/hooks/useAuth';
import { useUpdateProfile } from '#/api/userQueries';
import BlurryPurchaseCover from '#/components/shared/ui/BlurryPurchaseCover';
import Iconify from '#/components/shared/ui/Iconify';
import { useSubmitDocument } from '#/api/servicesQueries';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';
import ShareDocumentModal from '#/components/shared/ShareDocumentModal';
import { LinkToSettings } from '#/components/pages/PowerAttorney/steps/content/YourPowerOfAttorney/highlight-formats';

type Props = {
  outputValues?: ILivingWillForm | any;
};

const YourLivingWill = ({ outputValues }: Props) => {
  const [documentUpdated, setDocumentUpdated] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const handleOpenShareModal = () => setOpenShareModal(true);
  const handleCloseShareModal = () => setOpenShareModal(false);
  const handleDocUpdated = () => setDocumentUpdated(true);

  const { translate } = useLocales();
  const { state } = useLocation();
  const { user, refetch } = useAuth();
  const { mutateAsync: updateProfile } = useUpdateProfile();
  // const { handleDisableDownloads } = useDisableDownloads('living_will');

  const { mutateAsync: submitDocument, isLoading: submitDoc } =
    useSubmitDocument('livingwill');

  const { data: primaryRepresentative } = useGetRepresentative(
    outputValues?.primary_representative,
    !!outputValues?.primary_representative
  );

  const { data: secondaryRepresentative } = useGetRepresentative(
    outputValues?.secondary_representative,
    !!outputValues?.secondary_representative
  );

  const translatedNextSteps = Array.from({ length: 3 }, (_, i) =>
    translate(`livingWill.stepFive.nextStep${i + 1}`)
  );

  // const moreDetails =
  //   getValues?.('treatment_goal_sudden_incapacity') &&
  //   getValues?.('suffering_relief_sudden_incapacity') &&
  //   getValues?.('treatment_goal_prolonged_incapacity') &&
  //   getValues?.('suffering_relief_prolonged_incapacity') &&
  //   getValues?.('treatment_goal_permanent_incapacity') &&
  //   getValues?.('suffering_relief_permanent_incapacity');

  const generatePdfDocument = async () => {
    const blob = await pdf(
      <LivingWillDoc
        outputValues={{
          ...outputValues,
          representative: primaryRepresentative,
          secondary_representative: secondaryRepresentative,
        }}
      />
    ).toBlob();

    const formData = new FormData();
    formData.append(
      'document',
      blob,
      `${String(
        translate('global.services.livingWill')
      )}_${outputValues?.first_name}_${outputValues?.last_name}_${new Date()
        .toLocaleDateString('en-GB')
        .split('/')
        .join('-')}.pdf`
    );

    await submitDocument(formData)
      .then(() => {
        saveAs(
          blob,
          `${String(
            translate('global.services.livingWill')
          )}_${outputValues?.first_name}_${outputValues?.last_name}_${new Date()
            .toLocaleDateString('en-GB')
            .split('/')
            .join('-')}.pdf`
        );
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.document_submit'))
        );
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updateProfile({
      // eslint-disable-next-line no-unsafe-optional-chaining
      downloaded: ['living_will'],
    }).then(() => refetch());
  };

  useEffect(() => {
    if (documentUpdated && !outputValues?.automatically_show_popup) {
      handleOpenShareModal();
    }

    return () => {
      handleCloseShareModal();
    };
    // eslint-disable-next-line
  }, [outputValues?.signed_document, outputValues?.automatically_show_popup]);

  const hideConfetti =
    user?.downloaded?.includes('living_will') || state?.markAsDone;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {!hideConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={400}
          style={{ width: '100%' }}
        />
      )}
      <Stack
        spacing={2}
        sx={{
          marginBottom: 5,
        }}
      >
        <Typography gutterBottom>
          {String(translate('livingWill.stepFive.introParagraph'))}
        </Typography>
        <Typography gutterBottom>
          {String(translate('livingWill.stepFive.nextStepsTitle'))}
        </Typography>
        {translatedNextSteps.map((step, i) => (
          <Stack
            direction="row"
            alignItems="center"
            key={`key-step${i}`}
            spacing={1}
          >
            <Typography gutterBottom>{i + 1}.</Typography>
            <Typography gutterBottom>
              {i !== 1 ? (
                String(step)
              ) : (
                <LinkToSettings
                  context={String(step)}
                  targets={['hier klicken']}
                />
              )}
            </Typography>
          </Stack>
        ))}
        {/* <Typography variant="body2" gutterBottom> */}
        {/*  {String(translate('livingWill.stepFive.helperParagraph'))} */}
        {/* </Typography> */}
        {/* {!moreDetails && ( */}
        {/*  <Typography */}
        {/*    sx={{ */}
        {/*      display: { */}
        {/*        xs: 'block', */}
        {/*        lg: 'flex', */}
        {/*      }, */}
        {/*      gap: 1, */}
        {/*    }} */}
        {/*    variant="body2" */}
        {/*  > */}
        {/*    {highlightWords( */}
        {/*      String(translate('livingWill.stepFive.helperParagraph2')), */}
        {/*      [ */}
        {/*        String(translate('livingWill.stepFive.here')), */}
        {/*        String(translate('livingWill.stepFive.eightMinutes')), */}
        {/*      ], */}
        {/*      setActiveStep */}
        {/*    )} */}
        {/*  </Typography> */}
        {/* )} */}
        <UploadSignedDoc
          serviceName="livingwill"
          documentType="livingWill"
          serviceInvalidateKey="living-will"
          documentUpdated={handleDocUpdated}
        />
      </Stack>
      {outputValues?.signed_document && state?.markAsDone && (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleOpenShareModal}
          >
            <Typography>
              {String(translate('global.shareDocument.button'))}
            </Typography>
          </Button>
        </Stack>
      )}
      {!state?.markAsDone && (
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '50%',
            },
            margin: '0 auto',
          }}
        >
          {/* {handleDisableDownloads() ? (
            <BlurryPurchaseCover
              service="livingWill.stepFive.document"
              user={user}
            />
          ) : ( */}
          <PDFViewer
            style={{
              width: '100%',
              height: '550px',
            }}
            showToolbar={false}
          >
            <LivingWillDoc
              outputValues={{
                ...outputValues,
                representative: primaryRepresentative,
                secondary_representative: secondaryRepresentative,
              }}
            />
          </PDFViewer>
          {/* )} */}

          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Button
              disabled={submitDoc}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={generatePdfDocument}
              startIcon={
                submitDoc ? (
                  <Iconify icon="line-md:loading-twotone-loop" />
                ) : null
              }
            >
              <Typography>{String(translate('global.download'))}</Typography>
            </Button>
            {outputValues?.document_history[0]?.signed_document && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 2 }}
                onClick={handleOpenShareModal}
              >
                <Typography>
                  {String(translate('global.shareDocument.button'))}
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      )}
      <ShareDocumentModal
        open={openShareModal}
        closeModal={handleCloseShareModal}
      />
    </Box>
  );
};

// function highlightWords(
//   sentence: string,
//   targetPhrases: string[],
//   setActiveStep?: Dispatch<SetStateAction<number>>
// ): React.ReactNode[] {
//   let elements: React.ReactNode[] = [sentence];
//   targetPhrases.forEach((phrase, i) => {
//     elements = elements.flatMap((el, j) => {
//       if (typeof el === 'string') {
//         const parts = el.split(new RegExp(`(${phrase})`, 'gi'));
//         return parts.map((part, k) => {
//           if (part.toLowerCase() === phrase.toLowerCase()) {
//             if (
//               phrase.toLowerCase() === 'here,' ||
//               phrase.toLowerCase() === 'hier'
//             ) {
//               return (
//                 <Typography
//                   variant="body2"
//                   key={`${i}-${j}-${k}`}
//                   sx={{ color: '#3366FF', cursor: 'pointer', marginX: '-4px' }}
//                   onClick={() => setActiveStep && setActiveStep(3)}
//                 >
//                   {part}
//                 </Typography>
//               );
//             }
//             return (
//               <Typography
//                 variant="body2"
//                 key={`${i}-${j}-${k}`}
//                 sx={{ color: '#3366FF', marginX: '-4px' }}
//               >
//                 {part}
//               </Typography>
//             );
//           }
//           return part;
//         });
//       }
//       return el;
//     });
//   });
//   return elements;
// }

export default YourLivingWill;
